@charset "utf-8";

@font-face {
  font-family: "Berry Rotunda";
  src: url("/assets/fonts/Berry Rotunda.ttf");
}

$brand-color:           #ffc501 !default;
$brand-color-dark:      #d78e00 !default;

$text-color:            #fff3cb !default;
$background-color:      #0a0000 !default;

$link-base-color:       #f3c866 !default;
$link-visited-color:    $link-base-color !default;

$border-color:          $text-color !default;

$header-color:          $brand-color !default;

$table-text-color:      lighten($text-color, 18%) !default;
$table-zebra-color:     lighten($brand-color, 46%) !default;
$table-header-bg-color: lighten($brand-color, 43%) !default;
$table-header-border:   lighten($brand-color, 36%) !default;
$table-border-color:    $border-color !default;